<template>
  <div class="flex h-full w-full flex-col items-center px-10 py-20">
    <p class="mb-3 text-center text-4xl font-black text-amber-700">
      Make your life
    </p>
    <img src="/eventful-logo.png" class="mb-20 w-full max-w-md" />
    <p class="mb-4 max-w-4xl text-center text-2xl font-semibold text-amber-800">
      Eventful is an app that helps you meet special people.
    </p>
    <p
      class="mb-14 max-w-4xl text-center text-2xl font-semibold text-amber-800"
    >
      By making events invite-only, it takes you to where your people are.
    </p>
    <p
      class="mb-6 max-w-4xl px-10 text-center text-2xl font-semibold text-amber-800"
    >
      Install now:
    </p>
    <div class="mb-14 flex items-center justify-evenly">
      <a
        href="https://apps.apple.com/app/eventful-meet-people/id6443906697"
        class="mr-5"
        ><img src="/apple-app-store-badge.png"
      /></a>
      <a
        href="https://play.google.com/store/apps/details?id=is.eventful.eventful"
        ><img src="/google-play-badge.png"
      /></a>
    </div>
    <div class="flex-1"></div>
    <footer class="space-x-5">
      <NuxtLink to="/terms" class="mt-4 text-blue-700 hover:underline"
        >Terms</NuxtLink
      >
      <NuxtLink to="/privacy" class="mt-4 text-blue-700 hover:underline"
        >Privacy</NuxtLink
      >
    </footer>
  </div>
</template>

<script setup lang="ts">
const title = 'Eventful';

useHead({
  title,
});

useServerSeoMeta({
  title,
  description: 'Events where you meet wonderful people',
  ogDescription: 'Events where you meet wonderful people',
  ogImage: 'https://eventful.is/android-chrome-512x512.png',
  ogImageSecureUrl: 'https://eventful.is/android-chrome-512x512.png',
});
</script>
